import { css } from '@emotion/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Icon, Text, Title, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import {
  textTransitionInitialOptions,
  textTransitionTimingFunction
} from '../../../../../styles/transitions';
import { GATSBY_CDN_URL } from '../../../../../utils/env';
import { URLS } from '../../../../../utils/urls';
import useMainButtonText from '../../../commons/hooks/useMainButtonText';
import useIsPassedHeroSection from './useIsPassedHeroSection';

const Hero = () => {
  const { isPassedHeroSection } = useIsPassedHeroSection();
  const { buttonText, buttonDisabled } = useMainButtonText();

  return (
    <Box
      css={{
        width: '100%',
        overflowX: 'hidden'
      }}>
      {!isPassedHeroSection && (
        <>
          <motion.video
            css={[styleHeroVideo, { [desktopFirstMediaQuery.mobile]: { display: 'none' } }]}
            id="infcon-2023-hero-video"
            poster={`${GATSBY_CDN_URL}/infcon/infcon-2023/infcon2023-main-pc.png`}
            autoPlay
            loop
            muted
            playsInline
            src={`${GATSBY_CDN_URL}/infcon/infcon-2023/infcon2023-main-pc.mp4`}
          />
          <motion.video
            css={[
              styleHeroVideo,
              {
                display: 'none',
                [desktopFirstMediaQuery.mobile]: { display: 'inherit' }
              }
            ]}
            id="infcon-2023-hero-video"
            poster={`${GATSBY_CDN_URL}/infcon/infcon-2023/infcon2023-main-mo.png`}
            autoPlay
            loop
            muted
            playsInline
            src={`${GATSBY_CDN_URL}/infcon/infcon-2023/infcon2023-main-mo.mp4`}
          />
        </>
      )}
      <Box component="section" css={[styleHero, { opacity: isPassedHeroSection ? 0 : 100 }]}>
        <Title order={1} className="visually-hidden">
          인프콘 2023 메인 페이지
        </Title>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            delay: 0.3,
            times: 0.3
          }}>
          <Box css={styleHeroOverlay} />
        </motion.div>
        <Box css={styleHeroBody}>
          <motion.div
            animate={{ y: 0 }}
            initial={textTransitionInitialOptions}
            whileInView={{ opacity: 1 }}
            transition={{
              ...textTransitionTimingFunction,
              delay: 0.3
            }}>
            <img
              src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-hero-title-pc.svg`}
              alt="INFCON 2023"
              css={styleHeroPCTitle}
            />
            <img
              src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-hero-title-mb.svg`}
              alt="INFCON 2023"
              css={styleHeroMobileTitle}
            />
          </motion.div>
          <motion.div
            animate={{
              y: 0,
              opacity: 1
            }}
            initial={textTransitionInitialOptions}
            transition={{
              ...textTransitionTimingFunction,
              delay: 0.4
            }}>
            <Text size={32} color="white" weight="bold" mb={40} css={styleHeroSubTitle}>
              2023.08.15. 삼성동 COEX 그랜드볼룸 & 아셈볼룸
            </Text>
            <Box mb={40} css={styleHeroMobileSubTitle}>
              <Text size={28} color="white" weight="bold">
                2023.08.15.
              </Text>
              <Text size={28} color="white" weight="bold">
                삼성동 COEX 그랜드볼룸 & 아셈볼룸
              </Text>
            </Box>
          </motion.div>
          <motion.div
            animate={{
              y: 0,
              opacity: 1
            }}
            initial={textTransitionInitialOptions}
            transition={{
              ...textTransitionTimingFunction,
              delay: 0.6
            }}>
            <Button
              component="a"
              // TODO: 인프콘 2024에서 부활
              // href={URLS.INFCON_2023_COURSE_PAGE}
              href={URLS.INFCON_2023_REPLAY}
              target="_blank"
              size="xl"
              radius="xl"
              variant="filled"
              // leftIcon={<Icon icon={faBell} />}
              leftIcon={<Icon icon={faArrowUpRightFromSquare} css={{ svg: { width: '20px' } }} />}
              color="dark"
              css={{
                pointerEvents: 'all',
                svg: { width: '20px !important', height: '20px !important' }
              }}
              disabled={buttonDisabled}>
              {buttonText}
              {buttonText !== '신청 마감' && buttonText !== '인프콘 다시보기' && '하기'}
            </Button>
          </motion.div>
        </Box>
      </Box>
      <Box
        css={{
          position: 'relative',
          zIndex: 0,
          height: 'calc(100vh - 40px)',
          paddingTop: 'calc(100vh - 40px)'
        }}
      />
    </Box>
  );
};

const styleHero = css({
  zIndex: 1,
  position: 'fixed',
  top: '40px',
  width: '100%',
  height: 'calc(100vh - 40px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',

  [desktopFirstMediaQuery.mobile]: {
    height: '100vh'
  }
});

const styleHeroOverlay = css({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.05)'
});
const styleHeroBody = css({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: '40px'
});

const styleHeroPCTitle = css({
  marginBottom: 32,
  [desktopFirstMediaQuery.mobile]: {
    display: 'none'
  }
});
const styleHeroMobileTitle = css({
  display: 'none',
  marginBottom: 32,
  [desktopFirstMediaQuery.mobile]: {
    display: 'block'
  }
});
const styleHeroSubTitle = css({
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 28
  },
  [desktopFirstMediaQuery.mobile]: {
    display: 'none'
  }
});
const styleHeroMobileSubTitle = css({
  display: 'none',
  textAlign: 'center',
  [desktopFirstMediaQuery.mobile]: {
    display: 'block',
    p: {
      fontSize: 20
    }
  }
});

const styleHeroVideo = css({
  zIndex: -1,
  width: '100%',
  height: 'calc(100% - 40px)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  objectFit: 'cover',

  [desktopFirstMediaQuery.mobile]: {
    height: '100vh'
  }
});

export default Hero;
